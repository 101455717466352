<template>
    <div class="contaner">
        <div class="header fsc" v-if="msg.type == 1">
            <van-icon name="cross" @click="close" />
            <div>全部账单</div>
        </div>
        <div class="header fsc" v-if="msg.type == 2">
            <van-icon name="cross" @click="close" />
            <div>全部账单</div>
        </div>
        <div class="header fl" v-if="msg.type == 3">
            <van-icon name="cross" @click="close" />
            <div class="fl_text">详情</div>
        </div>
        <div class="content1" v-if="msg.type == 1">
            <div class="center">

                <img :src="msg.avatar" alt="" class="content1_avatar">
                <div class="content1_name">转账-转给{{ msg.name }}</div>
                <div class="content1_value">-{{ msg.value }}</div>
            </div>
            <div class="content1_list">
                <div class="content1_item">
                    <div class="content1_left">当前状态</div>
                    <div class="content1_right">对方已收钱</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">转账说明</div>
                    <div class="content1_right">{{ msg.text }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">转账时间</div>
                    <div class="content1_right">{{ msg.time }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">收款时间</div>
                    <div class="content1_right">{{ msg.time }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">支付方式</div>
                    <div class="content1_right">零钱通</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">转账单号</div>
                    <div class="content1_right">{{ msg.number }}</div>
                </div>
            </div>
            <div class="split"></div>
            <div class="bill">
                <div class="billtitle">账单服务</div>
            </div>
            <div class="btns">
                <div class="btn">
                    <img src="/images/wenhao.png" alt="">
                    <div>对订单有疑惑</div>
                </div>
                <div class="btn">
                    <img src="/images/huihua.png" alt="">
                    <div>定位到聊天位置</div>
                </div>
                <div class="btn">
                    <img src="/images/pingzheng.png" alt="">
                    <div>申请转账电子凭证</div>
                </div>
                <div class="btn">
                    <img src="/images/qiehuan.png" alt="">
                    <div>查看往来转账</div>
                </div>
            </div>
        </div>
        <div class="content2" v-if="msg.type == 2">
            <div class="center">

                <img :src="msg.avatar" alt="" class="content1_avatar">
                <div class="content1_name">扫二维码付款-给{{ msg.text }}</div>
                <div class="content1_value">-{{ msg.value }}</div>
            </div>
            <div class="content1_list">
                <div class="content1_item">
                    <div class="content1_left">当前状态</div>
                    <div class="content1_right">支付成功</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">收款方备注</div>
                    <div class="content1_right">二维码收款</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">支付方式</div>
                    <div class="content1_right">零钱通</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">转账时间</div>
                    <div class="content1_right">{{ msg.time }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">转账单号</div>
                    <div class="content1_right">{{ msg.number }}</div>
                </div>
            </div>
            <div class="split"></div>
            <div class="bill">
                <div class="billtitle">账单服务</div>
            </div>
            <div class="btns">
                <div class="btn">
                    <img src="/images/wenhao.png" alt="">
                    <div>对订单有疑惑</div>
                </div>
               
            </div>
            <div class="split"></div>
            <div class="bill">
                <div class="billtitle">收款方服务</div>
            </div>
            <div class="btns">
                <div class="btn">
                    <img src="/images/mingpian.png" alt="">
                    <div>收款方名片</div>
                </div>
               
            </div>
        </div>
        <div class="content3" v-if="msg.type == 3">
            <div class="center">

                <img :src="msg.avatar" alt="" class="content1_avatar">
                
<span  v-if="msg.text != '零钱通转出-到零钱' && msg.text != '零钱通转出-到银行卡'" >
    
    
                   <div v-if="msg.text != '转入零钱通-来自银行卡' && msg.text != '转入零钱通-来自零钱'" class="content1_name" >收益-{{ msg.text }}</div>                 
                   <div v-else   class="content1_name">{{ msg.text }}</div>
                <div class="content1_value">+{{ msg.value }}</div> 
</span>                
<span  v-if="msg.text == '零钱通转出-到零钱' || msg.text == '零钱通转出-到银行卡'" >
                   <div class="content1_name" >{{ msg.text }}</div>
                <div class="content1_value">-{{ msg.value }}</div> 
</span>  
            </div>
            <div class="content1_list">
                <div class="content1_item">
                    <div class="content1_left">零钱通余额</div>
                    <div class="content1_right">{{ msg.remain }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">交易时间</div>
                    <div class="content1_right">{{ msg.time }}</div>
                </div>
                <div class="content1_item">
                    <div class="content1_left">交易单号</div>
                    <div class="content1_right">{{msg.number }}</div>
                </div>
            </div>

        </div>
        <div class="bottom">本服务由财富通提供</div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            msg: {
                type: 1 // 1 微信转账 2 二维码付款 3收益
            }
        }
    },
    created() {
        this.msg = this.$route.query
    },
    methods: {
        close() {
            this.$router.go(-1)
        }
    }
}


</script>
<style scoped>
.contaner {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    
    flex-direction: column
}

.header {
    height: 50px;
    font-size: 16px;
}

.fsc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content1_right{
    font-weight: 600;
}
.fl {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.fl_text {
    flex: 1;
    text-align: center;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
}

.content1 {
    margin-top: 20px;

}

.content1_avatar {
    width: 50px;
}

.content1_name {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 600;
}

.content1_value {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
}

.content1_list {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    padding-top: 18px;
    padding-bottom: 24px;
}

.content1_item {
    display: flex;
    align-items: center;
    height: 30px;
}

.content1_left {
    color: #9b9b9b;
    margin-right: 10px;
    width: 100px;
 
}

.split {
    height: 10px;
    width: 100vw;
    background: #e0e0e0;
    margin-left: -10px;
}

.billtitle {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 20px;
    border-bottom: 1px solid #e2e2e2;
}

.btns {
    color: #397092;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    padding-left: 10px;
    padding-bottom: 16px;
}

.btn {
    width: 50%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.btn img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.bottom {
    flex: 1;
    background: #e0e0e0;
    text-align: center;
    width: 100vw;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9b9b9b;
}
</style>